import React, { useEffect } from 'react'
import { useStore } from '@src/store'
import { userActions } from '@store/actions/user'
import { cartActions } from '@store/actions/cart'
import { useAppStatus } from '@src/store/hooks/status'
import SimpleLoader from '@cmp/ui/loading/SimpleLoader'
import Layout from '@cmp/layouts/main/Layout'
import { useOrdersByStatusAndFeedbacks, useOrdersIP } from '@src/store/hooks/orders'
import { COMPLETED } from '@helpers/statusOrders'
import Routes from './routes/Routes'
import Offline from './pages/offline/Offline'
import ModalFeedback from './pages/ModalFeedback'

const App = () => {
  const [ { user }, dispatch ]:any = useStore()
  const { authenticated = {} } = user
  const { company } = user.authenticated

  const { data: appStatus, status: queryAppStatus } = useAppStatus()
  const isAuthenticated = (company || false) && !!Object.keys(authenticated).length

  const { data: ordersFeedbacks = [] } = useOrdersByStatusAndFeedbacks(isAuthenticated, COMPLETED, false)

  const { data: ordersIP = [] } = useOrdersIP(isAuthenticated)

  useEffect(() => {
    // check if we can get a user based on stored token
    userActions.get(dispatch)

    // init cart infos 
    async function getCartUser() {
      const cartId = localStorage.getItem('cartId')
      if (cartId) {
        await cartActions.restoreCart(cartId, dispatch)
      }
    }
    getCartUser()
  }, [])

  if (queryAppStatus === 'loading' || user.isLoadingGet) {
    return <SimpleLoader full />
  }

  if (queryAppStatus !== 'success') {
    return <Offline />
  }
  
  return (
    <>
      {ordersFeedbacks.length !== 0 && (
      <ModalFeedback
        ordersFeedbacks={ ordersFeedbacks }
      />
      )}
      <Layout
        authenticated={ authenticated }
        lastOrderIP={ ordersIP[0] }
      >
        <Routes
          appStatus={ appStatus }
        />
      </Layout>
    </>
  )
}

export default App
