import { useQuery } from 'react-query'
import { callApi } from '@helpers/api'

const QUERY_NAME = 'status'
const ENDPOINT = 'status'

export const useAppStatus = () => {
  return useQuery(QUERY_NAME, () => callApi({
    method: 'GET',
    endpoint: `${ENDPOINT}`,
    withAuth: false,
  }), {
    refetchOnWindowFocus: true,
    staleTime: 1000,
    // refetchOnReconnect: false,
    // refetchOnMount: false,
    retry: false,
  })
}
