import { useMutation, useQuery } from 'react-query'
import { callApi } from '@helpers/api'

const QUERY_NAME = 'fridges'

export const useOpenFridge = (id: any) => {
  return useMutation(
    () => callApi({
      method: 'POST',
      endpoint: `machine/open/${id}`,
    }),
    {
      onError: (error:any) => {
        return error
      },
      onSuccess: (res:any) => {
        return res
      },
      onSettled: () => { },
    },
  )
}

export const useFridgesCompany = (id:any) => {
  return useQuery(QUERY_NAME, () => callApi({
    method: 'GET',
    endpoint: `${QUERY_NAME}bycompany/${id}`,
  }), {
    refetchOnWindowFocus: false, // refetch when window comes to focus
    refetchOnReconnect: false, // refetch when browser reconnects to server
    refetchOnMount: false, // refetch when component mounts
  })
}
