import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { StateProvider } from '@store'
import { initialState, reducer } from '@store/reducers'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { THEME } from '@config/theme'
import ScrollToTop from '@cmp/utils/ScrollToTop'
import { SnackbarProvider } from 'notistack'
import { ReactQueryDevtools } from 'react-query/devtools'
import { focusManager, QueryClient, QueryClientProvider } from 'react-query'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import * as serviceWorker from './serviceWorker'
import App from './components/App'
import './index.scss'


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [ new Integrations.BrowserTracing() ],
  tracesSampleRate: 1.0,
  // debug: true,
  environment: process.env.REACT_APP_ENVIRONMENT,
})
// keep querying out of focus...
// allows sounds notification
focusManager.setFocused(true)

const queryClient = new QueryClient()
const theme = createTheme(THEME.mui)
const history = createBrowserHistory()

const renderApp = () => {
  return (
    <Router history={ history } basename="/">
      <ScrollToTop>
        <StateProvider initialState={ initialState } reducer={ reducer }>
          <MuiThemeProvider theme={ theme }>
            <SnackbarProvider { ...THEME.snackbar }>
              <QueryClientProvider client={ queryClient }>
                <ReactQueryDevtools initialIsOpen />
                <App />
              </QueryClientProvider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </StateProvider>
      </ScrollToTop>
    </Router>
  )
}

ReactDOM.render(renderApp(), document.getElementById('root'))
serviceWorker.unregister()
