export const CHECK_PAYMENT = 'checkPayment'
export const WAIT_4_SUPPLIER_APPROVAL = 'wait4SupplierApproval'
export const CHARGED_PAYMENT = 'chargedPayment'
export const AUTHORIZED_PAYMENT = 'authorizedPayment'
export const IN_PREPARATION = 'inPreparation'
export const WAIT_4_COLLECT = 'wait4Collect'
export const COLLECTED = 'collected'
export const COMPLETED = 'completed'
export const PAYMENT_REFUSED = 'paymentRefused'
export const CANCELED_BY_CLIENT = 'canceledByClient'
export const REFUSED_BY_SUPPLIER = 'refusedBySupplier'
export const MANUAL_VERIF_REQ = 'manualVerificationRequired'
export const ACCEPTED_BY_SUPPLIER = 'acceptedBySupplier'
export const RETRY_PAYMENT = 'retryPayment'


export const inProgressStatus = [ CHECK_PAYMENT, WAIT_4_SUPPLIER_APPROVAL, CHARGED_PAYMENT, AUTHORIZED_PAYMENT,
  IN_PREPARATION, WAIT_4_COLLECT, ACCEPTED_BY_SUPPLIER, MANUAL_VERIF_REQ, RETRY_PAYMENT ]
export const passedStatus = [ COLLECTED, COMPLETED, PAYMENT_REFUSED, CANCELED_BY_CLIENT, REFUSED_BY_SUPPLIER ]
