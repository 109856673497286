import { useQuery, useMutation } from 'react-query'
import { callApi } from '@helpers/api'

const QUERY_NAME = 'feedbacks'

export const useFeedbacksSpotties = () => {
  return useQuery(QUERY_NAME, () => callApi({
    method: 'GET',
    endpoint: `${QUERY_NAME}-spotties`,
  }), {
    refetchOnWindowFocus: false, // refetch when window comes to focus
    refetchOnReconnect: false, // refetch when browser reconnects to server
    refetchOnMount: false, // refetch when component mounts
  })
}

export const useFeedbacksFrigo = () => {
  return useQuery(QUERY_NAME, () => callApi({
    method: 'GET',
    endpoint: `${QUERY_NAME}-frigo`,
  }), {
    refetchOnWindowFocus: false, // refetch when window comes to focus
    refetchOnReconnect: false, // refetch when browser reconnects to server
    refetchOnMount: false, // refetch when component mounts
  })
}

export const useCloseFeedback = () => {
  return useMutation(
    (values:any) => callApi({
      method: 'POST',
      endpoint: `${QUERY_NAME}/close`,
      data: values,
    }),
    {
      onError: (error:any) => {
        return error
      },
      onSuccess: (res:any) => {
        return res
      },
      onSettled: () => { },
    },
  )
}

export const useUpdateFeedback = () => {
  return useMutation(
    (params: any) => callApi({
      method: 'POST',
      endpoint: `${QUERY_NAME}`,
      data: params,
    }),
    {
      onError: (error:any) => {
        return error
      },
      onSuccess: (res:any) => {
        return res
      },
      onSettled: () => { },
    },
  )
}
