/* eslint-disable no-restricted-syntax */
import { actionTypes } from '@store/actions/types'
import { callApi } from '@helpers/api'

const ENDPOINT = 'planograms'

const getCurrentPlanogram = async (id, dispatch) => {
  try {
    const currentPlanogram = await callApi({
      method: 'GET',
      endpoint: `${ENDPOINT}/current/${id}`,
    })

    return dispatch({
      type: actionTypes.GET_CURRENT_PLANOGRAM,
      payload: { currentPlanogram },
    })
  } catch (error) {
    return error
  }
}

export const planogramActions = {
  getCurrentPlanogram,
}
