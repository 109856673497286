import bon from '@assets/images/feedbacks/bon.png'
import mauvais from '@assets/images/feedbacks/mauvais.png'
import tresbon from '@assets/images/feedbacks/tresbon.png'
import bof from '@assets/images/feedbacks/bof.png'
import excellent from '@assets/images/feedbacks/excellent.png'

export const reactionsArray = [ {
  title: 'firstStar',
  image: mauvais,
  message: 'Mauvais',
  background: 'background-red',
  value: 1,
},
{
  title: 'secondStar',
  image: bof,
  message: 'Bof...',
  background: 'background-orange-sodexo',
  value: 2,
}, {
  title: 'thirdStar',
  image: bon,
  message: 'Bon',
  background: 'background-yellow',
  value: 3,
}, {
  title: 'fourthStar',
  image: tresbon,
  message: 'Très bon',
  background: 'background-green',
  value: 4,
}, {
  title: 'fifthStar',
  image: excellent,
  message: 'Parfait !',
  background: 'background-green',
  value: 5,
} ]
