import { actionTypes } from '@store/actions/types'

export const initialState = {
  authenticated: {},
  isLoadingLogin: true,
  isLoadingGet: true,
}

export const reducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isLoadingLogin: true,
      }
    case actionTypes.GET_USER_REQUEST:
      return {
        ...state,
        isLoadingGet: true,
      }
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.RESET_PASSWORD_SUCCESS:
    case actionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        authenticated: payload.user,
        isLoadingLogin: false,
      }
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        authenticated: payload.user,
        isLoadingGet: false,
      }
    case actionTypes.LOGIN_FAILURE:
    case actionTypes.REGISTER_FAILURE:
      return {
        ...state,
        isLoadingLogin: false,
      }
    case actionTypes.GET_USER_FAILURE:
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoadingLogin: false,
        isLoadingGet: false,
        authenticated: {},
      }
    // case actionTypes.SET_SUPPLIER:
    //   return {
    //     ...state,
    //     supplier: payload.supplier,
    //   }
    default:
      return state
  }
}
