import * as userStore from '@store/reducers/user'
import * as cartStore from '@store/reducers/cart'
import * as planogramStore from '@store/reducers/planogram'
import * as fridgeStore from '@store/reducers/fridge'

export const initialState = {
  user: userStore.initialState,
  cart: cartStore.initialState,
  planogram: planogramStore.initialState,
  fridge: fridgeStore.initialState,
}

export const reducer = ({
  user,
  cart,
  planogram,
  fridge,
}, action) => {
  return {
    user: userStore.reducer(user, action),
    cart: cartStore.reducer(cart, action),
    planogram: planogramStore.reducer(planogram, action),
    fridge: fridgeStore.reducer(fridge, action),
  }
}
