import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import styles from './Modal.module.scss'

interface Props{
  children:React.ReactElement
  fullScreen?:boolean
  dialogMaxWidth?:false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined,
  open: boolean,
  closeFromOutside: any
  id?:any
  refs?: any
  closeIcon?:boolean
  returnMention?:boolean
}

const useStyles = makeStyles((theme) => ({
  paperDialog: {
    borderRadius: '0px !important',
  },
  rootContent: {
    padding: '0px !important',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.between('xs', 'md')]: {
      height: '100vh',
    },
  },

}))

const Modal: React.FC<Props> = ({
  children,
  fullScreen = false,
  dialogMaxWidth = 'sm',
  open = false,
  closeFromOutside,
  refs = null,
  id,
  closeIcon = true,
  returnMention,
}) => {
  const classes = useStyles()

  const childrenWithProps = React.cloneElement(children)

  return (
    <Dialog
      fullScreen={ fullScreen }
      open={ open }
      scroll="paper"
      fullWidth
      maxWidth={ dialogMaxWidth }
      classes={ {
        paper: classes.paperDialog,
      } }
      onClose={ (event, reason) => {
        if (reason !== 'backdropClick') {
          closeFromOutside(false)
        }
      } }
      ref={ refs }
      id={ id }
    >
      <div>
        <DialogContent
          classes={ {
            root: classes.rootContent,
          } }
        >
          {returnMention && (
          <button onClick={ () => closeFromOutside(false) } className={ cn('reset', styles.mentionClose) }>
            Retour
          </button>
          )}
          {closeIcon && (
          <button onClick={ () => closeFromOutside(false) } className={ cn('reset', styles.btnClose) }>
            <i className="icon-close" />
          </button>
          )}
          {childrenWithProps}
        </DialogContent>
      </div>
    </Dialog>

  )
}
export default withMobileDialog()(Modal)
