import React, { useEffect, useState } from 'react'
import { useFeedbacksSpotties, useFeedbacksFrigo } from '@store/hooks/feedbacks'
import style from './feedback.module.scss'

interface Props {
  cartInfos: any,
  reactionSelected:any,
  onSelectedReason: any,
  prevValueFeedback: any
}

const ReasonsFeedbacks: React.FC<Props> = ({
  cartInfos, reactionSelected, onSelectedReason,
  prevValueFeedback,
}) => {
  const [ listReasonsSelected, setListReasonsSelected ] = useState([]) as any
  const [ listReasons, setListReasons ] = useState([])

  let feedbacksList = null as any
  if (cartInfos.fridge === null) {
    feedbacksList = useFeedbacksSpotties()
  } else {
    feedbacksList = useFeedbacksFrigo()
  }

  useEffect(() => {
    if (feedbacksList && reactionSelected) {
      setListReasons(feedbacksList.data[reactionSelected.title])
      if (reactionSelected.value !== prevValueFeedback) {
        setListReasonsSelected([])
      }
    }
  }, [ reactionSelected ])

  useEffect(() => {
    onSelectedReason({ listReasonsSelected }, { listReasons })
  }, [ listReasonsSelected ])

  const selectReasons = (reason: any) => {
    if (feedbacksList && reactionSelected) {
      const isSelected = listReasonsSelected.includes(reason)
      if (!isSelected) {
        setListReasonsSelected([ ...listReasonsSelected, reason ])
      } else {
        setListReasonsSelected(listReasonsSelected.filter((element: any) => element.id !== reason.id))
      }
    }
  }

  
  if (feedbacksList && reactionSelected) {
    return (
      <div className={ style.feedbackReasonContainer }>
        {feedbacksList.data[reactionSelected.title].map((element: any) => (
          <div
            key={ element.id }
            onClick={ () => selectReasons(element) }
            className={ listReasonsSelected.includes(element) ? style.reasonSelected : null }
          >{element.reason}
          </div>
        ))}
      </div>
    )
  }
  return null
}

export default ReasonsFeedbacks
