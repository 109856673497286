export const actionTypes = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_FAILURE',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  ADD_TO_CART: 'ADD_TO_CART',
  RESTORE_CART: 'RESTORE_CART',
  ADD_PICKINGTIME: 'ADD_PICKINGTIME',
  REMOVE_ITEM_CART: 'REMOVE_ITEM_CART',
  REMOVE_CART: 'REMOVE_CART',
  GET_CURRENT_PLANOGRAM: 'GET_CURRENT_PLANOGRAM',
  ADD_EDENRED_CARD: 'ADD_EDENRED_CARD',
  GET_CURRENT_FRIDGE: 'GET_CURRENT_FRIDGE',
}
