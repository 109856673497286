import { actionTypes } from '@store/actions/types'

export const initialState = {
  id: null,
  cartInfos: {},
  items: [],
  pickingTime: null,
}

export const reducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case actionTypes.ADD_TO_CART:
      return {
        ...state,
        id: payload.allCart.id,
        items: payload.allCart.items,
        cartInfos: payload.allCart.cartInfos,
      }
    case actionTypes.RESTORE_CART:
      return {
        ...state,
        id: payload.allCart.id,
        items: payload.allCart.items,
        cartInfos: payload.allCart.cartInfos,
      }
    case actionTypes.UPDATE_ORDER:
      return {
        ...state,
        updateOrder: true,
      }
    case actionTypes.ADD_PICKINGTIME:
      return {
        ...state,
        pickingTime: payload.time,
      }
    case actionTypes.REMOVE_ITEM_CART:
      return {
        ...state,
        id: payload.allCart.id,
        items: payload.allCart.items,
        cartInfos: payload.allCart.cartInfos,
      }
    case actionTypes.REMOVE_CART:
      return {
        ...state,
        id: initialState.id,
        items: initialState.items,
        cartInfos: initialState.cartInfos,
      }
      
    default:
      return state
  }
}
