import React, { useEffect, useState } from 'react'
import Modal from '@cmp/ui/modal/Modal'
import cn from 'classnames'
import Rating from '@material-ui/lab/Rating'
import { reactionsArray } from '@helpers/reactionsFeedbacks'
import { usePrevious } from '@src/hooks'
import { useCloseFeedback, useUpdateFeedback } from '@store/hooks/feedbacks'
import { formattedFeedbackReasons } from '@helpers/feedbacks'
import Header from './feedbacks/Header'
import style from './feedbacks/feedback.module.scss'
import ReasonsFeedbacks from './feedbacks/ReasonsFeedbacks'

interface Props {
  ordersFeedbacks: any
}

const ModalFeedback: React.FC<Props> = ({ ordersFeedbacks }) => {
  const [ valueFeedback, setValueFeedback ] = useState(0)
  const prevValueFeedback:number = usePrevious(valueFeedback) || 0
  const [ openModal ] = useState(true) as any
  const [ feedbackComments, setFeedbackComments ] = useState('')
  const [ reasonsSelected, setReasonsSelected ] = useState({}) as any
  const { mutate: closeFeedbackUpdate, status: closeFeedbackStatus } = useCloseFeedback()
  const { mutate: updateFeedback, status: updateFeedbackStatus } = useUpdateFeedback()

  useEffect(() => {
    if (updateFeedbackStatus === 'success' || closeFeedbackStatus === 'success') {
      setFeedbackComments('')
      setValueFeedback(0)
      setReasonsSelected({})
    }
  }, [ closeFeedbackStatus, updateFeedbackStatus ])

  const onSelectedReason = (listReasonsSelected: any, listReasons: any) => {
    setReasonsSelected({ ...reasonsSelected, listReasonsSelected, listReasons })
  }

  const createFeedback = async (userId: any, orderId: any) => {
    const currentModal = window.document.getElementById(orderId) as any
    if (reasonsSelected) {
      const { listReasons, listReasonsSelected } = reasonsSelected
      const reasons = await formattedFeedbackReasons(listReasons, listReasonsSelected)
      window.document.body.removeAttribute('style')
      currentModal.remove()
      await updateFeedback({
        user: userId,
        order: orderId,
        rating: valueFeedback,
        reasons,
        comment: feedbackComments,
      })
    }
  }

  const closeFeedbackModal = async(orderId: any) => {
    const currentModal = window.document.getElementById(orderId) as any
    window.document.body.removeAttribute('style')
    currentModal.remove()
    await closeFeedbackUpdate({ idOrder: orderId, feedbackSubmitted: true, feedback: null })
  }
  
  return (
    ordersFeedbacks.map((orderFeedback:any) => {
      const { id, cart } = orderFeedback
      const { cartInfos } = cart
      const reactionSelected = reactionsArray.find((element:any) => element.value === valueFeedback)
      return (
        <Modal
          open={ openModal }
          dialogMaxWidth="md"
          aria-describedby=""
          closeFromOutside={
            () => closeFeedbackModal(id)
          }
          id={ id }
          key={ id }
        >
          <div className={ cn(style.reactionContainer, reactionSelected ? reactionSelected.background : 'background-white') }>
            <Header cart={ cart } />
            {reactionSelected && (
            <div style={ { height: 80, display: 'flex' } }>
              <img src={ reactionSelected.image } className={ style.imageReaction } />
            </div>
            )}
            <div className="text-center">
              <Rating
                name="size-large"
                size="large"
                value={ reactionSelected ? reactionSelected.value : 0 }
                onChange={ (event, newValue: any) => {
                  setValueFeedback(newValue)
                  setFeedbackComments('')
                } }
              />
            </div>
            <ReasonsFeedbacks
              cartInfos={ cartInfos }
              reactionSelected={ reactionSelected }
              onSelectedReason={ onSelectedReason }
              prevValueFeedback={ prevValueFeedback }
            />
            {reactionSelected && (
              <div className={ style.formGroup }>
                <label htmlFor="instructions"><i className="icon-comment" /> Ajouter un commentaire</label>
                <textarea name="instructions" id="instructions" value={ feedbackComments } onChange={ (e:any) => setFeedbackComments(e.target.value) } />
              </div>
            )}
            <button
              className={ cn('cta cta--fullWidth cta--blue-sodexo', valueFeedback === 0 && 'cta--disabled') }
              onClick={ () => createFeedback(cartInfos.user.id, id) }
            >Envoyer
            </button>
          </div>
        </Modal>
      )
    })
  )
}
export default ModalFeedback
