import { useQuery, useMutation } from 'react-query'
import { callApi } from '@helpers/api'
import { REFETCH_SUPPLIER_INTERVAL } from '@src/config/main'

const QUERY_NAME = 'orders'
const ENDPOINT = 'orders'

export const useOrders = () => {
  return useQuery(`${QUERY_NAME}-list`, () => callApi({
    method: 'GET',
    endpoint: `${ENDPOINT}?_sort=created_at:DESC`,
  }), {
    refetchOnWindowFocus: false, // refetch when window comes to focus
    refetchOnReconnect: false, // refetch when browser reconnects to server
    refetchOnMount: true, // refetch when component mounts
    refetchInterval: REFETCH_SUPPLIER_INTERVAL, // refetch every ms
  })
}

export const useOrderById = (id: any) => {
  return useQuery([ 'order-id', id ], () => callApi({
    method: 'GET',
    endpoint: `${ENDPOINT}/${id}`,
  }), {
    retry: false,
    refetchOnWindowFocus: false, // refetch when window comes to focus
    refetchOnReconnect: false, // refetch when browser reconnects to server
    refetchOnMount: false, // refetch when component mounts
    refetchInterval: REFETCH_SUPPLIER_INTERVAL, // refetch every ms
  })
}
export const useOrdersByStatusAndFeedbacks = (isConfirmed:any, status:any, feedback:any) => {
  return useQuery(QUERY_NAME, () => callApi({
    method: 'GET',
    endpoint: `${ENDPOINT}?status=${status}&feedbackSubmitted=${feedback}&_sort=created_at:DESC`,
  }), {
    enabled: isConfirmed,
    retry: false,
    refetchOnWindowFocus: false, // refetch when window comes to focus
    refetchOnReconnect: false, // refetch when browser reconnects to server
    refetchOnMount: false, // refetch when component mounts
    refetchInterval: REFETCH_SUPPLIER_INTERVAL, // refetch every ms
  })
}

export const useOrdersIP = (isConfirmed: any) => {
  return useQuery('order-in-preparation', () => callApi({
    method: 'GET',
    endpoint: `${ENDPOINT}?status=inPreparation&status=wait4Collect&_sort=created_at:DESC`,
  }), {
    enabled: isConfirmed,
    retry: false,
    refetchOnWindowFocus: false, // refetch when window comes to focus
    refetchOnReconnect: false, // refetch when browser reconnects to server
    refetchOnMount: false, // refetch when component mounts
    refetchInterval: REFETCH_SUPPLIER_INTERVAL, // refetch every ms
  })
}

export function useCreateOrder() {
  return useMutation(
    (values:any) => callApi({
      method: 'POST',
      endpoint: `${ENDPOINT}`,
      data: values,
    }),
    {
      onError: (error:any) => {
        return error
      },
      onSuccess: (res:any) => {
        return res
      },
      onSettled: () => { },
    },
  )
}
