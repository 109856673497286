import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { PATHS } from '@config/nav'
import { useStore } from '@store'

const AuthRoute = ({ component: Component, ...rest }) => {
  const [ { user: { authenticated } } ] = useStore()
  const isAuthenticated = !!Object.keys(authenticated).length

  return (
    <Route
      { ...rest }
      render={ (props) => {
        if (!isAuthenticated) return <Component { ...props } />
        return <Redirect to={ PATHS.root } />
      } }
    />
  )
}

export default AuthRoute
