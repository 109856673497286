import React, { FC } from 'react'
import cn from 'classnames'
import loader from '@assets/images/loader.gif'
import styles from './SimpleLoader.module.scss'

interface Props{
  style?:any,
  full?:boolean
}


const SimpleLoader : FC<Props> = ({ style = null, full }) => {
  return (
    <div className={ cn(styles.SimpleLoader) }>
      <div className={ cn(styles.wrapper, full && styles.fullPage) }>
        <div className={ styles.loader } style={ style }>
          <img src={ loader } alt="" />
        </div>
      </div>
    </div>
  )
}

export default SimpleLoader

