export function truncate(str, n, useWordBoundary) {
  if (str.length <= n) {
    return str
  }
  const subString = str.substr(0, n - 1) // the original check
  return `${useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString}...`
}


export function formatCB(value) {
  // Convert a string to format 0000 0000 0000 0000
  const cb = String(value)
    .replace(/#/g, '*')
    .match(/.{1,4}/g)
  return String(cb).replace(/,/g, ' ')
}

export function formatPassRestaurant(value) {
  return String(value).slice(15, 19)
}


export function capitalize(s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}


export function formatHours(hour, identifian) {
  const splitHour = hour.split(':')
  return `${splitHour[0]}${identifian}${splitHour[1]}`
}

export function next5Minutes() {
  const time = 1000 * 60 * 5
  const date = new Date()
  const rounded = new Date(date.getTime() + time - (date.getTime() % time))
  return rounded
}

export function parseTime(time) {
  const today = new Date()
  today.setHours(time.split(':')[0])
  today.setMinutes(time.split(':')[1])
  return today
}

export function formatCard(ref) {
  const last2Char = ref.slice(-2)
  return `***** **${last2Char}`
}

export function formatPickupTime(hour, identifiant, timing) {
  const hourConvert = new Date(hour)
  hourConvert.setMinutes(new Date(hour).getMinutes() + timing)
  const splitHour = new Date(hourConvert).toLocaleTimeString('fr-FR').split(':')
  
  return `${splitHour[0]}${identifiant}${splitHour[1]}`
}

export function formatDistance(distance) {
  return parseInt(distance, 10) < 1000 ? `${distance} m` : `${distance.split('')[0]}.${distance.split('')[1]} km`
}
