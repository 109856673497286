import { actionTypes } from '@store/actions/types'

export const initialState = {
  currentFridge: '',
}

export const reducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case actionTypes.GET_CURRENT_FRIDGE:
      return {
        ...state,
        currentFridge: payload,
      }
    default:
      return state
  }
}
