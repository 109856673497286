import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { PATHS } from '@config/nav'
import PrivateRoute from '@cmp/utils/PrivateRoute'
import AuthRoute from '@cmp/utils/AuthRoute'
import SimpleLoader from '@cmp/ui/loading/SimpleLoader'
import { AppStatus } from '@src/interfaces'
import Offline from '../pages/offline/Offline'

const Authenticate = lazy(() => import('@cmp/pages/authenticate/Authenticate'))
const ForgotPassword = lazy(() => import('@cmp/pages/authenticate/ForgotPassword'))
const Register = lazy(() => import('@cmp/pages/authenticate/register/Register'))
const RegisterCodeCompany = lazy(() => import('@cmp/pages/authenticate/register/RegisterCodeCompany'))
const RegisterPassRestaurant = lazy(() => import('@src/components/pages/authenticate/register/RegisterRestaurantCard'))
const RegisterPaymentLMK = lazy(() => import('@cmp/pages/authenticate/register/RegisterPaymentLMK'))
const ResetPassword = lazy(() => import('@cmp/pages/authenticate/ResetPassword'))
const NotFound = lazy(() => import('@cmp/pages/notFound/NotFound'))
const Home = lazy(() => import('@cmp/pages/home/Home'))
const homepageFridge = lazy(() => import('@cmp/pages/fridge/home/Homepage'))
const productFridge = lazy(() => import('@cmp/pages/fridge/product/Product'))
const homepageSpotties = lazy(() => import('@cmp/pages/spotties/home/Homepage'))
const singleSpotties = lazy(() => import('@cmp/pages/spotties/spotty/Spotty'))
const singleOrderInProgress = lazy(() => import('@cmp/pages/account/SingleOrderInProgress'))
const singleOrderCompleted = lazy(() => import('@cmp/pages/account/SingleOrderCompleted'))
const account = lazy(() => import('@cmp/pages/account/Account'))
const profil = lazy(() => import('@cmp/pages/account/Profil'))
const creditCart = lazy(() => import('@cmp/pages/account/CardSources'))
const passRestaurant = lazy(() => import('@cmp/pages/account/CardSources'))
const FAQ = lazy(() => import('@cmp/pages/account/FAQ'))
const HelpAndSupport = lazy(() => import('@cmp/pages/account/HelpAndSupport'))
const RegisterCreditCard = lazy(() => import('@cmp/pages/authenticate/register/RegisterCreditCard'))
const AccountResetPassword = lazy(() => import('@cmp/pages/account/AccountResetPassword'))

interface Props{
  appStatus: AppStatus
}

const Routes: React.FC<Props> = ({ appStatus }) => {
  const ready = {
    auth: appStatus.auth,
    home: appStatus.order && appStatus.offer && appStatus.product,
  }

  return (
    <Suspense fallback={ <SimpleLoader full /> }>
      <Switch>
        <AuthRoute exact path={ PATHS.authenticate } component={ ready.auth ? Authenticate : Offline } />
        <Route exact path={ PATHS.register } component={ ready.auth ? Register : Offline } />
        <Route exact path={ PATHS.codeCompany } component={ ready.auth ? RegisterCodeCompany : Offline } />
        <Route
          exact
          path={ PATHS.passRestaurantRegister }
          component={ ready.auth ? RegisterPassRestaurant : Offline }
        />
        <Route exact path={ PATHS.creditCardRegister } component={ ready.auth ? RegisterCreditCard : Offline } />
        <Route exact path={ PATHS.paymentSuccess } component={ ready.auth ? RegisterPaymentLMK : Offline } />
        <Route exact path={ PATHS.paymentFailed } component={ ready.auth ? RegisterPaymentLMK : Offline } />
        <Route exact path={ PATHS.paymentError } component={ ready.auth ? RegisterPaymentLMK : Offline } />
        <Route exact path={ PATHS.forgotPassword } component={ ready.auth ? ForgotPassword : Offline } />
        <Route exact path={ PATHS.resetPassword } component={ ready.auth ? ResetPassword : Offline } />

        <PrivateRoute exact path={ PATHS.root } component={ ready.home ? Home : Offline } />

        <PrivateRoute exact path={ PATHS.homepageFridge } component={ ready.home ? homepageFridge : Offline } />
        <PrivateRoute exact path={ `${PATHS.productFridge}/:slug` } component={ ready.home ? productFridge : Offline } />
        <PrivateRoute exact path={ PATHS.homepageSpotties } component={ ready.home ? homepageSpotties : Offline } />
        <PrivateRoute exact path={ `${PATHS.singleSpotties}/:id` } component={ ready.home ? singleSpotties : Offline } />
        <PrivateRoute exact path={ PATHS.account } component={ ready.home ? account : Offline } />
        <PrivateRoute exact path={ `${PATHS.ordersIP}/:id` } component={ ready.home ? singleOrderInProgress : Offline } />
        <PrivateRoute exact path={ `${PATHS.ordersCompleted}/:id` } component={ ready.home ? singleOrderCompleted : Offline } />
        <PrivateRoute exact path={ PATHS.profil } component={ ready.home ? profil : Offline } />
        <PrivateRoute exact path={ PATHS.creditCart } component={ ready.home ? creditCart : Offline } />
        <PrivateRoute exact path={ PATHS.passRestaurant } component={ ready.home ? passRestaurant : Offline } />
        <PrivateRoute exact path={ PATHS.FAQ } component={ ready.home ? FAQ : Offline } />
        <PrivateRoute exact path={ PATHS.HelpAndSupport } component={ ready.home ? HelpAndSupport : Offline } />
        {/* eslint-disable-next-line max-len */}
        <PrivateRoute exact path={ PATHS.accountResetPassword } component={ ready.home ? AccountResetPassword : Offline } />


        <Route component={ NotFound } />
      </Switch>
    </Suspense>
  )
}

export default Routes
