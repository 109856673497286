import React, { FC, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { PATHS } from '@src/config/nav'
import cn from 'classnames'
import { isAndroid, MobileView } from 'react-device-detect'
import logo from '@assets/images/logo-mobile.svg'
import Footer from '@cmp/layouts/footer/Footer'
import SubHeader from '@cmp/layouts/subheader/SubHeader'
import { AnimatePresence, motion } from 'framer-motion'
import moment from 'moment'
import { isWidthDown } from '@material-ui/core/withWidth'
import { useMuiWidth } from '@src/hooks'
import styles from './Layout.module.scss'
import OrderIPSection from '../subheader/OrderIPSection'


interface Props{
  children:React.ReactNode,
  authenticated:any,
  lastOrderIP:any
}

const Layout : FC<Props> = ({ children, authenticated, lastOrderIP }) => {
  const width = useMuiWidth()

  function getBanner() {
    const bannerCloseDate = localStorage.getItem('bannerLastClosed')
    if (bannerCloseDate) {
      const diff = moment().diff(bannerCloseDate, 'weeks')
      return !!diff
    }
    return true
  }

  const { isValidAccount, canPay } = authenticated
  const { pathname } = useLocation()
  const history = useHistory()
  const [ showBanner, setShowBanner ] = useState(getBanner()) as any

  const isAuthenticated = !!Object.keys(authenticated).length
  const isConfirmed = isAuthenticated && isValidAccount

  if (!isAuthenticated || !isConfirmed) {
    return (
      <>
        {children}
      </>
    )
  }

  function closeAppBanner() {
    localStorage.setItem('bannerLastClosed', moment().format())
    if (localStorage.getItem('bannerLastClosed')) {
      setShowBanner(false)
    }
  }

  return (
    <div className={ styles.Layout }>
      <div className={ styles.content }>
        {lastOrderIP && (
        <OrderIPSection
          order={ lastOrderIP }
          currentPath={ pathname }
          availablePaths={ [ PATHS.homepageSpotties ] }
        />
        )}
        {!canPay && (
        <div className={ styles.errorPaymentContainer }>
          <h2 className="text-center">Erreur de paiement</h2>
          <p className="text-center">
            Merci de mettre à jour vos moyens de paiement pour continuer à profiter
            de vos plats préférés et régulariser votre dernière commande.
          </p>
          <button
            className={ cn('cta cta--transparent', styles.buttonErrorPayment) }
            onClick={ () => history.push(PATHS.creditCart) }
          >Vérifier vos moyens de paiements
          </button>
        </div>
        )}
        <SubHeader />
        {children}
        <Footer />
        <AnimatePresence>
          {showBanner && (
          <motion.div
            initial={ { opacity: 0 } }
            animate={ { opacity: 1 } }
            exit={ { opacity: 0 } }
          >
            <MobileView>
              <div className={ styles.overlay } />
              <div className={ `container-fluid justify-center ${styles.downloadAppBanner}` }>
                <img src={ logo } height="auto" className={ styles.logoBanner } alt="logo" />
                {!isWidthDown('xs', width)
                && (
                <p className={ styles.titleApp }>
                  Le Food Spot
                </p>
                )}
                <Link
                  to={ { pathname: isAndroid ? 'https://play.google.com/store/apps/details?id=com.sodexo.lefoodspot&gl=FR' : 'https://apps.apple.com/fr/app/le-food-spot/id1545969464' } }
                  target="_blank"
                  className={ `cta cta--blue-sodexo ${styles.buttonDownload}` }
                >Ouvrir l&apos;app
                </Link>
                &nbsp;
                <button
                  className="cta cta--ghost--blue"
                  onClick={ () => closeAppBanner() }
                >Continuer
                </button>
              </div>
            </MobileView>
          </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default Layout
