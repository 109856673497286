import React from 'react'
import { Link } from 'react-router-dom'
import { formatPickupTime } from '@utils/validation'
import OrderIPImage from '@assets/images/order-ip-image.png'
import styles from './orderIPSection.module.scss'

interface Props {
  order: any,
  availablePaths: any,
  currentPath: any
}
const OrderIPSection: React.FC<Props> = ({ order, currentPath, availablePaths }) => {
  const { id, datePickup, supplier } = order

  return (
    availablePaths.includes(currentPath)
    && (
    <Link to={ `/order-in-progress/${id}` } className={ styles.sectionContainer }>
      <div>
        <img src={ OrderIPImage } alt="order-in-preparation" />
        <p>En cuisine...</p>
        <p>{supplier.title}</p>
      </div>
      <div>
        <p>{formatPickupTime(datePickup, 'h', 0)} - {formatPickupTime(datePickup, 'h', 5)}</p>
        <p style={ { textDecoration: 'underline' } }>Voir ma commande</p>
      </div>
     
    </Link>
    )
  )
}
export default OrderIPSection
