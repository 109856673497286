import { actionTypes } from '@store/actions/types'

export const initialState = {
  currentPlanogramProducts: [],
}

export const reducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case actionTypes.GET_CURRENT_PLANOGRAM:
      return {
        ...state,
        currentPlanogramProducts: payload.currentPlanogram.products,
      }
    default:
      return state
  }
}
