export const formattedFeedbackReasons = ({ listReasons }, { listReasonsSelected }) => {
  const obj = {}
  const feedbackNotSelected = listReasons.filter((single) => !listReasonsSelected
    .some((element) => element.id === single.id))

  listReasonsSelected.forEach((single) => {
    obj[single.reason] = true
  })

  feedbackNotSelected.forEach((single) => {
    obj[single.reason] = false
  })
  
  return obj
}
