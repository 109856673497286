import React from 'react'
import { Helmet } from 'react-helmet'
import logo from '@assets/images/logo.png'
import styles from './Offline.module.scss'

const Offline = () => {
  return (
    <>
      <Helmet>
        <title>Service indisponible</title>
      </Helmet>
      <section className={ styles.Offline }>
        <img src={ logo } alt="logo" className={ styles.logo } />
        <h1>Désolé !</h1>
        <p>La page que vous recherchez ne semble pas accessible pour le moment. <br />
          Veuillez nous excuser pour cet inconvénient et réessayer plus tard.
        </p>
        <a href="mailto:contact@lefoodspot.com" className={ `cta ${styles.button}` }>
          Contactez le FoodSpot <i className="icon-arrow_right" />
        </a>
      </section>
    </>
  )
}

export default Offline
