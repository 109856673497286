/* eslint-disable no-restricted-syntax */
import { actionTypes } from '@store/actions/types'
import { callApi } from '@helpers/api'

const ENDPOINT_NAME = 'carts'

const createCart = async (supplier, productId, quantity, infos, options, dispatch) => {
  const cart = {
    cartInfos: {
      supplier,
    },
    items: [
      {
        __component: 'cart-item.product',
        product: productId,
        quantity,
        specificInformation: infos,
        options,
      },
    ],
  }
  try {
    const allCart = await callApi({
      method: 'POST',
      endpoint: `${ENDPOINT_NAME}`,
      data: { cartInfos: cart.cartInfos, items: cart.items },
    })
    localStorage.setItem('cartId', allCart.id.toString())

    return dispatch({
      type: actionTypes.ADD_TO_CART,
      payload: { allCart },
    })
  } catch (error) {
    return error
  }
}
const addProductToCart = async (cartId, product, quantity, options, specificInformation, dispatch) => {
  try {
    const allCart = await callApi({
      method: 'POST',
      endpoint: `${ENDPOINT_NAME}/${cartId}/items`,
      data: {
        __component: 'cart-item.product', product, quantity, options, specificInformation,
      },
    })
    return dispatch({
      type: actionTypes.ADD_TO_CART,
      payload: { allCart },
    })
  } catch (error) {
    return error
  }
}

const updateProductToCart = async (id, itemId, quantity, dispatch) => {
  try {
    const allCart = await callApi({
      method: 'PUT',
      endpoint: `${ENDPOINT_NAME}/${id}/items/${itemId}`,
      data: {
        id, itemId, quantity,
      },
    })
    return dispatch({
      type: actionTypes.ADD_TO_CART,
      payload: { allCart },
    })
  } catch (error) {
    return error
  }
}
const restoreCart = async (cartId, dispatch) => {
  try {
    const allCart = await callApi({
      method: 'GET',
      endpoint: `${ENDPOINT_NAME}/${cartId}`,
    })
    return dispatch({
      type: actionTypes.RESTORE_CART,
      payload: { allCart },
    })
  } catch (error) {
    localStorage.removeItem('cartId')
    return error
  }
}

const addPickingTime = async (time, dispatch) => {
  return dispatch({
    type: actionTypes.ADD_PICKINGTIME,
    payload: { time },
  })
}

const removeItem = async (cartId, itemId, dispatch) => {
  try {
    const allCart = await callApi({
      method: 'DELETE',
      endpoint: `${ENDPOINT_NAME}/${cartId}/items/${itemId}`,
    })
    return dispatch({
      type: actionTypes.REMOVE_ITEM_CART,
      payload: { allCart },
    })
  } catch (error) {
    return error
  }
}

const removeProduct = async (cart, productId, dispatch) => {
  try {
    let allCart = null
    const arrayItem = []
    cart.items.map(async (item) => {
      if (item.product.id === productId) {
        arrayItem.push(item.id)
      }
    })
    for await (const element of arrayItem) {
      allCart = await callApi({
        method: 'DELETE',
        endpoint: `${ENDPOINT_NAME}/${cart.id}/items/${element}`,
      })
    }
    const currentCart = await callApi({
      method: 'GET',
      endpoint: `${ENDPOINT_NAME}/${cart.id}`,
    })
    if (currentCart) {
      return dispatch({
        type: actionTypes.REMOVE_ITEM_CART,
        payload: { allCart },
      })
    }
    localStorage.removeItem('cartId')
    return dispatch({
      type: actionTypes.REMOVE_CART,
    })
  } catch (error) {
    return error
  }
}

const removeCart = async (cartId, dispatch) => {
  try {
    await callApi({
      method: 'DELETE',
      endpoint: `${ENDPOINT_NAME}/${cartId}`,
    })
    localStorage.removeItem('cartId')
    return dispatch({
      type: actionTypes.REMOVE_CART,
    })
  } catch (error) {
    return error
  }
}

const clearCart = async (cartId, dispatch) => {
  try {
    localStorage.removeItem('cartId')
    return dispatch({
      type: actionTypes.REMOVE_CART,
    })
  } catch (error) {
    return error
  }
}

export const cartActions = {
  createCart,
  addProductToCart,
  updateProductToCart,
  restoreCart,
  addPickingTime,
  removeItem,
  removeProduct,
  removeCart,
  clearCart,
}
