import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { PATHS } from '@config/nav'
import { useStore } from '@store'

const PrivateRoute = ({
  component: Component, path, ...rest
}) => {
  const [ { user: { authenticated } } ] = useStore()
  const {
    isValidAccount, passedCodeCompany, passedPassRestaurant, passedCreditCard,
  } = authenticated
  const { spottiesService, fridgeService } = authenticated.company || false
  const isAuthenticated = !!Object.keys(authenticated).length
  const isConfirmed = isValidAccount || false
 
  return (
    <Route
      { ...rest }
      render={ (props) => {
        if (isAuthenticated && isConfirmed) {
          return (
            <Component
              { ...props }
              spottiesService={ spottiesService }
              fridgeService={ fridgeService }
            />
          )
        }
        if (isAuthenticated && !isConfirmed) {
          if (passedCodeCompany !== true) {
            return <Redirect to={ PATHS.codeCompany } />
          }
          if (passedPassRestaurant !== true) {
            return <Redirect to={ PATHS.passRestaurantRegister } />
          }
          if (passedCreditCard !== true) {
            return <Redirect to={ PATHS.creditCardRegister } />
          }
        }
        return <Redirect to={ PATHS.authenticate } />
      } }
    />
  )
}

export default PrivateRoute
