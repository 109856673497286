import React, { FC } from 'react'

import { useMuiWidth } from '@src/hooks'
import { isWidthDown } from '@material-ui/core/withWidth'

import { Link, useLocation } from 'react-router-dom'
// Icons
import { ReactComponent as AccountSvg } from '@assets/images/icons/icon-account.svg'
import { ReactComponent as AccountWhiteSvg } from '@assets/images/icons/icon-account-white.svg'
import { ReactComponent as FridgeBig } from '@assets/images/icons/icon-fridge-big.svg'
import { ReactComponent as SpottiesBig } from '@assets/images/icons/icon-spotties-big.svg'
import { ReactComponent as SpottiesActiveBig } from '@assets/images/icons/icon-spotties-active-big.svg'
import { ReactComponent as FridgeActiveBig } from '@assets/images/icons/icon-fridge-active-big.svg'
import { isAndroid, isDesktop, isMobile } from 'react-device-detect'
import LogoFull from '@assets/images/logofull.svg'
import LogoMobileWhite from '@assets/images/logo-mobile-white.svg'
import LogoFullWhite from '@assets/images/logofull-white.svg'
import LogoMobile from '@assets/images/logo-mobile.svg'
import { useFridgesCompany } from '@store/hooks/fridge'
import { useStore } from '@src/store'
import { fridgeActions } from '@store/actions/fridge'
import { planogramActions } from '@store/actions/planogram'
import styles from './SubHeader.module.scss'

const SubHeader : FC = () => {
  const width = useMuiWidth()
  const location = useLocation()
  const [ { user, fridge: { currentFridge } }, dispatch ]:any = useStore()
  const { company } = user.authenticated
  const { data: fridges, isSuccess, isLoading } = useFridgesCompany(company.id)
  const isSpottiesHomepage = location.pathname.includes('homepage-spotties')
  const isFridgeHomepage = location.pathname === '/' || location.pathname.includes('homepage-fridge')

  const displayBG = () => {
    if (isFridgeHomepage) {
      return <div className={ `${styles.OrangeBanner} ${styles.FridgeBanner}` } />
    } if (isSpottiesHomepage) {
      return <div className={ `${styles.OrangeBanner} ${styles.SpottiesBanner}` } />
    }
    return null
  }

  const changeFridge = async (fridgeId: string) => {
    const findFridge = fridges.find((fdg: any) => fdg.id.toString() === fridgeId)
    await fridgeActions.getCurrentFridge(findFridge, dispatch)
    await planogramActions.getCurrentPlanogram(findFridge.id, dispatch)
  }

  const displayFridgeSelector = () => {
    return (
      <>
        <div className={ styles.subSelect }>Sélectionnez votre frigo</div>
        <select
          className={ styles.fridgeSelect }
          onChange={ async (e: any) => changeFridge(e.target.value) }
          value={ currentFridge.id }
          disabled={ isLoading }
        >
          {(isSuccess && fridges) && fridges.map((fdg: any, index: any) => {
            return <option key={ index.toString() } value={ fdg.id }>{fdg.title}</option>
          })}
        </select>
      </>
    )
  }

  return (
    <>
      <header className={ isSpottiesHomepage || (isFridgeHomepage && isSuccess && fridges.length === 1) ? `${styles.HeaderBig}` : isFridgeHomepage ? `${styles.HeaderSmall}` : '' }>
        <nav className={ isSpottiesHomepage || isFridgeHomepage ? `${styles.SubHeaderNoBackground} ${styles.SubHeader}` : `${styles.SubHeader}` } role="navigation" aria-label="navigation principale">
          <Link to="/" title="Le food spot" className={ styles.logo }>
            {!isWidthDown('sm', width) ? (
              <img src={ isSpottiesHomepage || isFridgeHomepage ? LogoFullWhite : LogoFull } alt="Le food spot" />
            ) : (
              <img src={ isSpottiesHomepage || isFridgeHomepage ? LogoMobileWhite : LogoMobile } alt="Le food spot" />
            )}
          </Link>
          {isMobile
          && (
          <Link
            to={ { pathname: isAndroid ? 'https://play.google.com/store/apps/details?id=com.sodexo.lefoodspot&gl=FR' : 'https://apps.apple.com/fr/app/le-food-spot/id1545969464' } }
            target="_blank"
            className={ `cta cta--grey ${styles.buttonApp}` }
          >Ouvrir l&apos;app
          </Link>
          )}
          {
             !isFridgeHomepage && !isSpottiesHomepage
              && (
              <ul className={ styles.subheaderList }>
                <li className={ location.pathname.includes('/product-fridge') ? `${styles.subheaderListItem} ${styles.active}` : `${styles.subheaderListItem}` }>
                  <Link to="/homepage-fridge" className={ styles.SubHeaderLink }>
                    <span className={ styles.subheaderListItemIcon }>
                      {
                        location.pathname.includes('/product-fridge') ? (
                          <i className="icon-ouvert" />
                        ) : (
                          <i className="icon-frigo" />
                        )
                      }
                    </span>

                    <span className="sm-hidden">Mon frigo</span>
                  </Link>
                </li>

                <li className={ location.pathname.includes('/spotties') ? `${styles.subheaderListItem} ${styles.active}` : `${styles.subheaderListItem}` }>
                  <Link to="/homepage-spotties" className={ styles.SubHeaderLink }>
                    <span className={ styles.subheaderListItemIcon }>
                      <i className="icon-spotties" />
                    </span>
                    <span className="sm-hidden">Mes spotties</span>
                  </Link>
                </li>
              </ul>
              )
            }
          <div className={ styles.right }>
            <div className={ styles.box }>

              {/* <DebugNav />
              <LogoutBtn /> */}

              <ul className={ styles.subheaderList }>
                <li className={ styles.subheaderListItem }>
                  <Link to="/account" title="Mon compte" className={ styles.SubHeaderLink }>
                    {
                      isSpottiesHomepage || isFridgeHomepage ? (<AccountWhiteSvg aria-hidden="true" />) : (<AccountSvg aria-hidden="true" />)
                    }
                  </Link>
                </li>
              </ul>

            </div>
          </div>
        </nav>
        {
          isSpottiesHomepage || isFridgeHomepage ? (
            <>
              <h1 className={ `${styles.HeaderTitle} sm-hidden` }>
                {
                isSpottiesHomepage ? (
                  <>
                    <span>Vos</span><br />
                    Spotties
                  </>
                ) : (
                  <>
                    <span>Votre</span><br />
                    Menu
                  </>
                )
              }
              </h1>
              {(isMobile && isSuccess && fridges.length > 1 && isFridgeHomepage)
              && (
              <div className={ styles.selectorBlock }>
                { displayFridgeSelector()}
                <div className={ styles.bubbleFridge }>
                  <span>{currentFridge.identifiant}</span>
                </div>
              </div>
              )}
              {(isDesktop || isSpottiesHomepage || (isSuccess && fridges.length === 1)) && displayBG()}
              <nav className={ `${styles.Banner}` }>
                <ul className={ styles.BannerList }>
                  <li className={ isFridgeHomepage ? `${styles.Fridge} ${styles.Active}` : `${styles.Fridge}` }>
                    <Link to="/homepage-fridge">
                      {
                      isFridgeHomepage ? (<FridgeActiveBig />) : (<FridgeBig />)
                    }
                      <span>Mon frigo</span>
                    </Link>
                  </li>
                  <li className={ isSpottiesHomepage ? `${styles.Spotties} ${styles.Active}` : `${styles.Spotties}` }>
                    <Link to="/homepage-spotties">
                      {
                      isSpottiesHomepage ? (<SpottiesActiveBig />) : (<SpottiesBig />)
                    }
                      <span>Mes spotties</span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </>
          ) : null
        }
      </header>
    </>
  )
}

export default SubHeader
