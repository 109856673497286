import { actionTypes } from '@store/actions/types'

const getCurrentFridge = async (currentFridge, dispatch) => {
  return dispatch({
    type: actionTypes.GET_CURRENT_FRIDGE,
    payload: currentFridge,
  })
}

export const fridgeActions = {
  getCurrentFridge,
}
