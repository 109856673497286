import React from 'react'
import Logo from '@assets/images/logo.png'
import style from './feedback.module.scss'

interface Props {
  cart: any
}

const Header: React.FC<Props> = ({ cart }) => {
  const { cartInfos } = cart
  const displayText = () => {
    return cartInfos.fridge === null ? `Comment était votre déjeuner chez ${cartInfos.supplier.title} ?`
      : 'Comment était votre déjeuner au Frigo spot ?'
  }
  return (
    <>
      <img src={ Logo } className={ style.logo } />
      <h2 className="text-center">{displayText()}</h2>
    </>
  )
}

export default Header
