/* eslint-disable no-param-reassign */
import { actionTypes } from '@store/actions/types'
import {
  getToken, callApi, storeToken, removeToken,
} from '@helpers/api'

const REMEMBER_LS = 'rememberMe'

const login = async (
  {
    values, onSuccess, setErrors, setSubmitting,
  },
  dispatch,
) => {
  dispatch({ type: actionTypes.LOGIN_REQUEST })

  const { rememberMe, ...data } = values

  if (rememberMe) {
    localStorage.setItem(REMEMBER_LS, 1)
  } else {
    localStorage.removeItem(REMEMBER_LS)
  }

  try {
    const { jwt, user } = await callApi({
      method: 'POST',
      endpoint: 'auth/local',
      data,
      withAuth: false,
    })
    // Not a client ?
    if (user.role.id === 1) {
      storeToken(jwt)
      dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: { user } })
      onSuccess()
    } else {
      setErrors({ fromApi: 'Role error' })
      dispatch({ type: actionTypes.LOGIN_FAILURE })
    }
  } catch (error) {
    // console.log({ error })
    setErrors({ fromApi: error })
    dispatch({ type: actionTypes.LOGIN_FAILURE })
  }
  setSubmitting(false)
}

const put = async (values, dispatch) => {
  try {
    const user = await callApi({
      method: 'PUT',
      endpoint: 'users/me',
      data: values,
    })
    dispatch({ type: actionTypes.UPDATE_SUCCESS, payload: user })
  } catch (error) {
    console.log({ error })
    dispatch({ type: actionTypes.UPDATE_FAILURE })
  }
}

const putUserProfile = async (
  {
    values, onSuccess, setErrors, setSubmitting,
  },
  dispatch,
) => {
  // never update company
  delete values.company
  try {
    const user = await callApi({
      method: 'PUT',
      endpoint: 'users/me',
      data: values,
    })
    dispatch({ type: actionTypes.UPDATE_SUCCESS, payload: user })
    onSuccess()
  } catch (error) {
    console.log({ error })
    setErrors({ error })
    dispatch({ type: actionTypes.UPDATE_FAILURE })
  }
  setSubmitting(false)
}

const changePassword = async (
  {
    values, onSuccess, setErrors, setSubmitting,
  },
  dispatch,
) => {
  // dispatch({ type: actionTypes.LOGIN_REQUEST })

  try {
    const user = await callApi({
      method: 'PUT',
      endpoint: 'users/me',
      data: values,
    })
    dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS, payload: { user } })
    onSuccess()
  } catch (error) {
    console.log({ error })
    setErrors({ fromApi: error })
    dispatch({ type: actionTypes.RESET_PASSWORD_FAILURE })
  }
  setSubmitting(false)
}

const resetPassword = async (
  {
    values, onSuccess, setErrors, setSubmitting,
  },
  dispatch,
) => {
  // dispatch({ type: actionTypes.LOGIN_REQUEST })

  try {
    const res = await callApi({
      method: 'POST',
      endpoint: 'auth/reset-password',
      data: values,
      withAuth: false,
    })
    console.log({ res })
    // dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS, payload: { user } })
    onSuccess()
  } catch (error) {
    console.log({ error })
    setErrors({ fromApi: error })
    dispatch({ type: actionTypes.RESET_PASSWORD_FAILURE })
  }
  setSubmitting(false)
}

const register = async (
  {
    values, onSuccess, setErrors, setSubmitting,
  },
  dispatch,
) => {
  try {
    const checkEmail = await callApi({
      method: 'GET',
      endpoint: `check-account/${values.email}`,
      withAuth: false,
    })
    if (checkEmail.status === 'OK') {
      values.username = values.email
      try {
        const { jwt, user } = await callApi({
          method: 'POST',
          endpoint: 'auth/local/register',
          data: values,
          withAuth: false,
        })
        storeToken(jwt)
        dispatch({ type: actionTypes.REGISTER_SUCCESS, payload: { user } })
        onSuccess()
      } catch (error) {
        setErrors({ fromApi: 'Wrong email' })
        dispatch({ type: actionTypes.REGISTER_FAILURE })
      }
    } else {
      setErrors({ fromApi: 'Wrong email' })
      dispatch({ type: actionTypes.REGISTER_FAILURE })
    }
  } catch (error) {
    setErrors({ fromApi: error })
    dispatch({ type: actionTypes.REGISTER_FAILURE })
  }
  setSubmitting(false)
}

const addCompanyUser = async (code, dispatch) => {
  try {
    const user = await callApi({
      method: 'GET',
      endpoint: `companies/code/${code}`,
    })
    dispatch({ type: actionTypes.GET_USER_SUCCESS, payload: { user } })
    return true
  } catch (error) {
    dispatch({ type: actionTypes.REGISTER_FAILURE })
    return false
  }
}

const forgotPassword = async (
  {
    values, onSuccess, setErrors, setSubmitting,
  },
  dispatch,
) => {
  try {
    const res = await callApi({
      method: 'POST',
      endpoint: 'auth/forgot-password',
      data: values,
      withAuth: false,
    })
    dispatch({ type: actionTypes.FORGOT_PASSWORD_SUCCESS, payload: { res } })
    onSuccess()
  } catch (error) {
    console.log({ error })
    setErrors({ fromApi: error })
    dispatch({ type: actionTypes.FORGOT_PASSWORD_FAILURE })
  }
  setSubmitting(false)
}

const logout = (dispatch) => {
  removeToken()
  dispatch({ type: actionTypes.LOGOUT_SUCCESS })
  // window.location.reload()
}

const get = async (dispatch) => {
  dispatch({ type: actionTypes.GET_USER_REQUEST })
  const token = getToken()

  if (!token) {
    return dispatch({ type: actionTypes.GET_USER_FAILURE })
  }

  try {
    const user = await callApi({
      method: 'GET',
      endpoint: 'users/me',
    })
    if (user.role.name === 'Clients') {
      return dispatch({
        type: actionTypes.GET_USER_SUCCESS,
        payload: { user },
      })
    }
    removeToken()
    return dispatch({ type: actionTypes.GET_USER_FAILURE })
  } catch (error) {
    removeToken()
    return dispatch({ type: actionTypes.GET_USER_FAILURE })
  }
}

export const userActions = {
  login,
  logout,
  get,
  register,
  put,
  putUserProfile,
  resetPassword,
  addCompanyUser,
  forgotPassword,
  changePassword,
}
