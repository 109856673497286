import React, { FC } from 'react'
import { Link } from 'react-router-dom'

// Android & iOS
import { ReactComponent as LogoFooter } from '@assets/images/logo-footer.svg'
import { ReactComponent as AndroidBannerSvg } from '@assets/images/store-android.svg'
import { ReactComponent as IOSBannerSvg } from '@assets/images/store-apple.svg'

// Social
import { ReactComponent as LinkedinSvg } from '@assets/images/icons/linkedin.svg'
import styles from './Footer.module.scss'

const Footer : FC = () => {
  const date = new Date()
  const year = date.getFullYear()
  // to avoid compilation error
  const globWindow: any = window

  return (
    <footer className={ styles.footer }>

      <div className={ styles.NewsLetter }>
        {/* <div className={ styles.NewsLetterLeft }>
          <h3 className={ styles.Title }>Restez informé</h3>
          <p className={ styles.Text }>Ne ratez rien de notre actu !</p>
        </div>

        <div className={ styles.NewsLetterRight }>
          <button type="button" className={ styles.BtnNewsLetter }>Recevoir la newsletter</button>
        </div> */}
      </div>

      <div className={ styles.FooterMiddle }>

        <div className={ styles.LogoBanner }>
          <LogoFooter />
        </div>

        <div className={ styles.FooterListContainer }>
          <div className={ styles.FooterList }>
            <ul>
              <li>
                <h3 className={ styles.FooterListTitle }>à propos</h3>
              </li>
              <li>
                <Link to="/account/help/">Contact</Link>
              </li>
              <li>
                <Link to={ { pathname: 'https://www.lefoodspot.com/presse/' } } target="_blank">Presse et media kit</Link>
              </li>
              <li>
                <Link to={ { pathname: 'https://www.lefoodspot.com/rejoignez-nous/' } } target="_blank">Rejoignez-nous !</Link>
              </li>
              <li>
                <Link to={ { pathname: 'https://www.lefoodspot.com/blog/' } } target="_blank">Journal</Link>
              </li>
              <li>
                <Link to={ { pathname: 'https://www.lefoodspot.com/aide/' } } target="_blank">FAQ</Link>
              </li>
            </ul>
          </div>

          <div className={ styles.FooterList }>
            <ul>
              <li>
                <h3 className={ styles.FooterListTitle }>légal</h3>
              </li>
              <li>
                <Link to="/">Termes et conditions</Link>
              </li>
              <li>
                <Link to="/">Mentions légales</Link>
              </li>
              <li>
                <Link to="/" onClick={ () => globWindow.OneTrust.ToggleInfoDisplay() }>Préférences de cookies</Link>
              </li>
            </ul>
          </div>

          <div className={ styles.FooterList }>
            <ul>
              <li>
                <h3 className={ styles.FooterListTitle }>le food spot toujours dans vos poches</h3>
              </li>
              <li>
                <Link to={ { pathname: 'https://play.google.com/store/apps/details?id=com.sodexo.lefoodspot&gl=FR' } } target="_blank" title="Google Play Store">
                  <AndroidBannerSvg />
                </Link>
              </li>
              <li>
                <Link to={ { pathname: 'https://apps.apple.com/fr/app/le-food-spot/id1545969464' } } target="_blank" title="App Store - Apple">
                  <IOSBannerSvg />
                </Link>
              </li>
            </ul>
          </div>
        </div>

      </div>

      <div className={ styles.FooterBottom }>
        <p>&copy; { year === 2021 ? 2021 : `2021 - ${year}` } Le Food Spot</p>

        <div className={ styles.SocialFooter }>
          <ul>
            <li>
              <Link to={ { pathname: 'https://www.linkedin.com/company/le-food-spot/' } } target="_blank" title="LinkedIn">
                <LinkedinSvg />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
