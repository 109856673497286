import { Link } from '@src/interfaces'

export const PATHS = {
  root: '/',
  authenticate: '/authenticate',
  forgotPassword: '/forgotPassword',
  register: '/register',
  codeCompany: '/company-code',
  passRestaurantRegister: '/pass-restaurant',
  creditCardRegister: '/credit-card',
  resetPassword: '/resetPassword',
  homepageFridge: '/homepage-fridge',
  productFridge: '/product-fridge',
  homepageSpotties: '/homepage-spotties',
  ordersIP: '/order-in-progress',
  ordersCompleted: '/order-completed',
  singleSpotties: '/spotties',
  singleProductSpotties: '/singleProductSpotties',
  cart: '/cart',
  listOrder: '/listOrder',
  singleOrderInProgress: '/singleOrderInProgress',
  singleOrderCompleted: '/singleOrderCompleted',
  account: '/account/',
  profil: '/account/profil',
  creditCart: '/account/credit-card/',
  passRestaurant: '/account/pass-restaurant/',
  FAQ: '/account/faq',
  contact: '/contact',
  apiCall: '/apiCall',
  HelpAndSupport: '/account/help/',
  accountResetPassword: '/account/resetPassword/',
  paymentSuccess: '/payment/success',
  paymentFailed: '/payment/failed',
  paymentError: '/payment/error',
}

export const LINKS:Link[] = [
  {
    to: PATHS.root,
    label: 'Accueil',
    icon: 'icon-spotties',
  },
  {
    to: PATHS.authenticate,
    label: 'Se connecter',
    isMain: false,
  },
]
