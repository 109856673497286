// see _variables.scss
export const COLORS = {
  primary: '#7eefb6',
  secondary: '#2a295c',
  blueLight: '#e1e6f3',
  blueLighter: '#f4f5f8',
  greyLight: '#f4f5f8',
}

// https://material-ui.com/customization/theming/
export const THEME = {
  ...COLORS,
  mui: {
    breakpoints: {
      keys: [ 'xs', 'sm', 'md', 'lg', 'xl' ],
      values: {
        xs: 0,
        sm: 568,
        md: 760,
        lg: 1200,
        xl: 1600,
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    palette: {
      primary: { main: COLORS.primary },
      secondary: { main: COLORS.secondary },
      action: {
        selected: '#dcebfb',
        hover: '#dcebfb',
      },
    },
    typography: {
      h6: {
        fontFamily: 'inherit',
      },
      button: {
        fontFamily: 'inherit',
      },
    },
  },
  snackbar: {
    autoHideDuration: 5000,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    classes: {
      variantSuccess: 'snack--success',
      variantError: 'snack--error',
      variantWarning: 'snack--warning',
      variantInfo: 'snack--info',
    },
  },
}
